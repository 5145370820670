.section5{
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.878)), url(/src/homepage/section4/background.png);
    display: flex;
    gap: 60px;
    justify-content: center;
    align-items: center;
    width: 100vw;
    left: -5vw;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: row-reverse;
    padding: 60px;
}
.section5 .infos{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.section5 .infos h2{
    font-size: 40px;
    font-family: var(--poppins);
    font-weight: 600;
}
.section5 .infos p{
    font-size: 16px;
    font-family: var(--karla);
    font-weight: 400;
    max-width: 800px;
}
.section5 .infos button {
    width: 146px;
    height: 48px;
    border: none;
    border: 1px solid;
    border-radius: 8px;
    background-color: transparent;
    font-family: var(--karla);
    font-size: 16px;
    font-weight: 500;
}
.section5 .shape{
    width: 378px;
    height: 378px;
    background-color: gray;
    background-image: url(./image.svg);
    background-position: center;
    background-size: cover;
}

@media (max-width:920px) {
    .section5{
        flex-direction: column-reverse;
        padding-inline: 20px;
        gap: 0;
    }
    .section5 .infos{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        gap: 16px;
        margin-top: 15px;
    }
    .section5 .shape{
        margin-top: 20px;
        width: 91%;
        height: 300px;
    }
    .section5 .infos h2{
        font-size: 30px;
        }
}