.section3{
    padding-top: 50px;
}
.section3 h2{
    font-family: var(--poppins);
    font-weight: 600;
    font-size: 40px;
    text-align: center;
}
.section3 p{
    font-family: var(--karla);
    font-weight: 400;
    font-size: 20px;
    text-align: center;
}
.section3 .join{
    margin-top: 50px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.section3 .join .first{
    display: flex;
    gap: 10px;
    position: relative;
    width: 400px;
}
.section3 .join .first::before{
    content: "";
    position: absolute;
    background-color: #0079DC;
    width: 230px;
    height: 2px;
    right: 0;
}
.section3 .join .first .img{
    width: 136px;
    height: 278px;
    background-color: gray;
    border-radius: 6px;
}
.section3 .join .first h2{
    font-size: 18px;
    font-family: var(--montserrat);
    font-weight: 700;
    margin-top: 20px;
    text-align: left;
}
.section3 .join .first p{
    font-size: 16px;
    font-family: var(--montserrat);
    font-weight: 400;
    width: 237px;
    text-align: left;
    margin-top: 12px;
}
.section3 .button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.section3 .button button{
    width: 201px;
    height: 48px;
    border: none;
    border: 1px solid;
    border-radius: 8px;
    background-color: transparent;
    font-size: 16px;
    font-family: var(--karla);
    font-weight: 500;
    margin-top: 25px;
}
.section3 .marks{
    display: flex;
    align-items: center;
    gap: 26px;
}
.slider{
    height: 250px;
    width: 90%;
    position: relative;
    display: grid;
    place-items: center;
    align-items: center;
    overflow: hidden;
    margin: auto;
    
}
.slide-track{
    display: flex;
    width: calc(250px * 20);
    animation: scroll 40s linear infinite;
}
.slide {
    height: 128px;
    width: 128px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}
.section3 img{
    width: 100%;
}
.section3 .join .first:first-child .img:first-child{
    background-image: url(./images/img1.svg);
    background-position: center;
    background-size: cover;
}
.section3 .join .first:nth-child(2) .img:first-child{
    background-image: url(./images/img2.svg);
    background-position: center;
    background-size: cover;
}
.section3 .join .first:last-child .img:first-child{
    background-image: url(./images/img3.svg);
    background-position: center;
    background-size: cover;
}
.section3 .img11{
    width: 50px;
}
@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100%{
        transform: translateX(calc(-128px * 10));
    }
}


@media (max-width:500px) {

    .section3 .join .first .img {
        width: 120px;
        height: 120px;
    }
    .section3 .join .first h2{
        font-size: 15px;
    }
    .section3 .join .first p{
        font-size: 13px;
    }
    .section3  {
        margin-top: 50px;
    }
}
@media (max-width:418px) {
    .section3{
        margin-top: 0px;
    }
}
@media (max-width:401px) {
    .section3 .join .first .img{
        width: 100px;
        height: 100px;
    }
    .section3 .join .first::before{
        right: 19px;
        width: 200px;
    }
}
@media (max-width:367px) {
    .section3 .join .first .img{
        width: 85px;
        height: 85px;
    }
    .section3 .join .first::before{
        right: 19px;
        width: 200px;
    }
}
