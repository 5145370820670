@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --poppins : "Poppins", sans-serif;
  --karla :  "Karla", sans-serif;
  --montserrat : "Montserrat", sans-serif;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
.App{
  padding-top: 25px;
  width: 90vw;
  margin: auto;
}
button,
input[type="button"]{
  cursor: pointer;
}
@media (max-width:628px) {
  .App{
    padding-top: 0;
  }
}